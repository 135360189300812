import React from "react"

import createSubscriptionImage from "/src/assets/landing/linkfive_create-subscriptions.png"
import manageSubscriptionImage from "/src/assets/landing/linkfive_manage-subscriptions.png"
import improveSubscriptionImage from "/src/assets/landing/linkfive_improve-your-subscriptions.png"
import deliverSubscriptionImage from "/src/assets/landing/linkfive_deliver-your-subscriptions-to-your-app.png"
import userManagementImage from "/src/assets/landing/linkfive_user-management.png"
import userManagementPurchaseOverviewImage from "/src/assets/landing/linkfive-purchase-overview.png"
import HeaderH3 from "./headerH3";
import BackgroundBox from "./blocks/background-box";
import UserManagement2Image from "/src/assets/user_management/user_management-2.png";
import ApiImg from "../assets/server-api/api.png";

export default function LandingBenefits(props) {
  return (
    <>
      <section className="bg-white py-8" id="product">
        <div className="container max-w-6xl mx-auto m-8 pt-12">
          <HeaderH3
            headerText="The In-App Subscriptions Service"
            widthClass="w-4/6"
          />
          <div className="flex flex-wrap mt-24">
            <div className="w-full sm:w-1/2 p-12">
              <img alt="Improve your Subscriptions with LinkFive"
                   title="Improve your Subscriptions with LinkFive"
                   src={deliverSubscriptionImage}/>
            </div>

            <div className="sm:w-1/2 p-6">
              <h3 className="mt-4 mb-8 text-4xl font-medium leading-10 tracking-tight text-gray-800">
                <b className="font-bold highlight">App Store connection</b> in a super simple way!
              </h3>
              <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                Your subscriptions are instantly delivered from the App Store to your app. We take care of all edge
                cases, so you can focus on building a better app.
              </p>
              <BackgroundBox>
                <ul className="list-disc p-4">
                  <li key="1">Subscriptions ready in 30 minutes</li>
                  <li key="2">We validate the receipt for you</li>
                  <li key="3">No subscription hardcoding. Replace your subscriptions in seconds</li>
                  <li key="4">We always make sure your subscription is valid</li>
                </ul>
              </BackgroundBox>
            </div>
          </div>



          <div className="flex flex-wrap sm:mt-44 ">

            <div className="w-full sm:w-1/2 rounded-lg overflow-hidden lg:hidden block p-12">
              <img alt="feature" className="object-cover object-center h-full w-full"
                   src={userManagementImage}/>
            </div>
            <div className="sm:w-1/2 p-6">
              <h3 className="mt-4 mb-8 text-4xl font-medium leading-10 tracking-tight text-gray-800">
                <span className="font-bold highlight">User Management</span> at its best
              </h3>
              <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                With our user management, you can see exactly who is using your app and who is purchasing your subscriptions.
              </p>
              <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                Manually assign your users a premium plan for a specific time or link different user accounts to an existing premium plan.
                Your users can even own and benefit from the same plan on multiple platforms.
              </p>
              <BackgroundBox>
                <ul className="list-disc p-4">
                  <li key="1">Multi platform subscription plans</li>
                  <li key="2">Enable team subscriptions</li>
                  <li key="3">Family subscription plans are even possible</li>
                </ul>
              </BackgroundBox>
            </div>
            <div className="w-full sm:w-1/2 p-12 lg:block hidden">
              <img alt="Improve your Subscriptions with LinkFive"
                   title="Improve your Subscriptions with LinkFive"
                   src={userManagementImage}/>
            </div>
          </div>



          <div className="flex flex-wrap sm:pt-44">
            <div className="w-full sm:w-1/2 ">
              <img alt="Manage Subscriptions with LinkFive"
                   title="Manage Subscriptions with LinkFive"
                   src={userManagementPurchaseOverviewImage}/>
            </div>

            <div className="sm:w-1/2 p-6">
              <h3 className="mt-4 mb-8 text-4xl font-medium leading-10 tracking-tight text-gray-800">
                <span className="font-bold highlight">Purchase Overview</span> for all users on all platforms
              </h3>
              <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                You can see all purchases for each of your users at a glance.
                <br/>
                <br/>
                If you want to give a user premium for a month through a customer request, you can simply create a custom plan for that user.
              </p>
            </div>
          </div>


          <div className="flex flex-wrap sm:pt-44 ">
            <div className="w-full sm:w-1/2 rounded-lg overflow-hidden lg:hidden block p-12">
              <img alt="feature" className="object-cover object-center h-full w-full"
                   src={UserManagement2Image}/>
            </div>

            <div className="sm:w-1/2 p-6">
              <h3 className="mt-4 mb-8 text-4xl font-medium leading-10 tracking-tight text-gray-800">
                <span className="font-bold highlight">We connect</span> all user sessions to your internal user ID
              </h3>
              <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                Whether your user is on Android, iOS or Web, we link all sessions to your own User ID and give you full control.
                <br/>
                <br/>
                And if one of these sessions makes a purchase, all user sessions will receive the same premium status.
              </p>
            </div>

            <div className="w-full sm:w-1/2 rounded-lg overflow-hidden lg:block hidden p-12">
              <img alt="feature" className="object-cover object-center h-full w-full"
                   src={UserManagement2Image}/>
            </div>
          </div>


          <div className="flex flex-wrap sm:mt-44">

            <div className="w-full sm:w-1/2 rounded-lg overflow-hidden lg:block hidden p-12">
              <img alt="feature" className="object-cover object-center h-full w-full"
                   src={ApiImg}/>
            </div>
            <div className="sm:w-1/2 p-6">
              <h3 className="mt-4 mb-8 text-4xl font-medium leading-10 tracking-tight text-gray-800">
                <span className="font-bold highlight">Server Api</span>
              </h3>
              <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">When a user purchases a product in your app, we store the purchase in our database and update the entry if the user cancels it. You can then retrieve the purchase with your server and our Api-Key, regardless of which app store the purchase was made in.</p><br/>
              <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">Our Server Api has several API-Endpoints. Please check out our <a href="https://app.swaggerhub.com/apis-docs/LinkFive/LinkFive-Server-Api/" target="_blank"> Swagger Documentation</a></p>
            </div>
            <div className="w-full sm:w-1/2 p-12 lg:hidden block">
              <img alt="Improve your Subscriptions with LinkFive"
                   title="Improve your Subscriptions with LinkFive"
                   src={ApiImg}/>
            </div>
          </div>


          <div className="flex flex-wrap sm:mt-44">

            <div className="w-full sm:w-1/2 rounded-lg overflow-hidden lg:hidden block p-12">
              <img alt="feature" className="object-cover object-center h-full w-full"
                   src={improveSubscriptionImage}/>
            </div>
            <div className="sm:w-1/2 p-6">
              <h3 className="mt-4 mb-8 text-4xl font-medium leading-10 tracking-tight text-gray-800">
                <span className="font-bold highlight">Test</span> new subscription prices in seconds
              </h3>
              <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                Modify in-app subscription prices with a finger click and make code changes a thing of the past.
              </p>
              <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">Enable your marketing team to run marketing
                campaigns targeted on country, app version, operating system and more. With LinkFive everything is
                dynamic, adjustable and customizable for your needs.</p>
              <BackgroundBox>
                <ul className="list-disc p-4">
                  <li key="1">A/B Testing for Prices</li>
                  <li key="2">Run Marketing Campaigns</li>
                  <li key="3">Configure global pricing</li>
                </ul>
              </BackgroundBox>
            </div>
            <div className="w-full sm:w-1/2 p-12 lg:block hidden">
              <img alt="Improve your Subscriptions with LinkFive"
                   title="Improve your Subscriptions with LinkFive"
                   src={improveSubscriptionImage}/>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
