import React from "react"

import {Link} from "gatsby"

import linkFiveCodeBlock from "/src/assets/landing/linkfive_code_block.png"

export default function LandingHero(props) {
  return (
    <>
      <div className="pt-24 bg-l6-background ">
        <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center lg:mt-12">
          <div className="lg:mb-24 mb-12 md:pl-14 md:pr-14 mb-10 md:mb-0 md:pt-16 lg:w-4/6 w-full flex flex-col items-start">
            <h1 className="font-extrabold leading-10 tracking-tight sm:leading-none  md:text-6xl sm:text-5xl text-4xl">
              Flutter In-App Subscription Management
            </h1>

            <p className="my-5 md:w-4/5 text-lg md:mx-0 text-gray-800">
              Easily implement app store subscriptions with our Flutter plugin and start growing your revenue.
            </p>
            <ul className="ml-6 list-disc text-lg text-gray-800">
              <li>Setup in-app subscriptions in minutes</li>
              <li>Configure new pricing with just a mouse click
              </li>
              <li>Advanced revenue Analytics for Flutter</li>
              <li>Extended Flutter Subscription User Management</li>
            </ul>

            <div className="inline-block">
              <a
                href="//app.linkfive.io/sign-up"
                className="action-button focus:shadow-outline focus:outline-none w-full sm:w-auto md:mt-5"
              >
                Start for Free
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Link to="/contact"
                    className="secondary-action-button focus:shadow-outline focus:outline-none w-full sm:w-auto md:mt-5 pl-10 inline-block"
                    state={{scheduleDemo: true}}>Schedule a Demo</Link>
            </div>
          </div>

          <div className="w-full md:w-2/6 py-6 lg:ml-auto mx-auto lg:block hidden">

            <img alt="LinkFive Code"
                 title="LinkFive Code"
                 className=""
                 src={linkFiveCodeBlock}/>
          </div>
        </div>
      </div>
    </>
  )
}
