import React from "react"

HeaderH3.defaultProps = {
  className: "",
  widthClass: "w-full"
};

export default function HeaderH3(props) {
return <>
    <h3 className={`${ props.className } ${ props.widthClass } py-2 text-4xl font-bold leading-tight text-center text-l5-secondary mx-auto`}>
      {props.headerText}
    </h3>
    <div className="w-full mb-4">
        <div className="h-2 mx-auto bg-l6-secondary md:w-96 w-64 my-0 py-0" style={{"marginTop": "-12px"}}/>
    </div>
  </>
}
