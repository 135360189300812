import React from "react"

import Layout from "../components/layout"

import Hero from "../components/landing-hero"
import Quotes from "../components/landing-quotes"
import LandingFunctions from "../components/landing-functions";
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import Feature3Blocks from "../components/tailblocks/feature_3_blocks_with_bg";
import WorldMap from "/src/assets/landing/linkfive_world_map.png"
import TrustpilotReview1 from "/src/assets/landing/trustpilot-review-62fb58f04c35e69ec52e488e.jpg"
import TrustpilotReview2 from "/src/assets/landing/trustpilot-review-6299b10e35343b8f5b5ff166.jpg"
import HeaderH3 from "../components/headerH3";

export default function Home(props) {
  return (
    <>
      <GatsbySeo title="LinkFive: Flutter In-App Purchase & Subscription Management"
                 description={`Improve your app store subscriptions and increase your in-app revenue with easy and customizable user targeting. Change your app subscription offer in seconds not in days.`}
      />
      <Layout fullWave="false" bottomWave="false" callToAction="true" utmSource="landing">
        <GatsbySeo
          titleTemplate="%s"/>

        <Hero/>

        <section className="text-gray-600 body-font">
          <div className="container px-5 pt-36 pb-12 mx-auto">
            <div className="flex flex-wrap -m-4 text-center justify-center">
              <div className="p-4 sm:w-1/4 w-1/2">
                <h2 className="title-font font-medium sm:text-4xl text-3xl text-gray-900">204</h2>
                <p className="leading-relaxed">Countries</p>
              </div>
              <div className="p-4 sm:w-1/4 w-1/2">
                <h2 className="title-font font-medium sm:text-4xl text-3xl text-gray-900">1 Million</h2>
                <p className="leading-relaxed">Purchase Events Every Month</p>
              </div>
              <div className="p-4 sm:w-1/4 w-1/2">
                <h2 className="title-font font-medium sm:text-4xl text-3xl text-gray-900">5</h2>
                <p className="leading-relaxed">Flutter Plugins</p>
              </div>
              <div className="p-4 sm:w-1/4 w-1/2">
                <h2 className="title-font font-medium sm:text-4xl text-3xl text-gray-900">2.02</h2>
                <p className="leading-relaxed">Subscriptions Offered per User</p>
              </div>
            </div>
          </div>
        </section>

        <Feature3Blocks title="Complete infrastructure for appstore subscriptions"
                        bp1Text="Integrate in-app subscriptions in your Flutter app without any server code. It only takes 4 lines of code."
                        bp1Title="Build" bp1Link="/docs/"
                        bp2Text="Powerful revenue and user acquisition analytics. Integrations with mobile analytics, marketing platforms and messengers."
                        bp2Title="Measure"
                        bp3Text="Run A/B experiments on your in-app subscriptions. Find the right pricing in order to maximise you revenue in all of your markets."
                        bp3Title="Grow"
        />

        <section className="text-gray-600 ">
          <div className="container max-w-6xl mx-auto m-8 pt-0">
            <HeaderH3
                headerText="We Are Going Open Source"
                widthClass="w-4/6"
            />
            <p className="lg:max-w-4xl w-full leading-relaxed mx-auto text-center p-6 text-lg ">
              We're making our server code open source to accelerate innovation. We believe that open source is the future of software development, and we're committed to making our code as accessible and easy to use as possible.
              We are currently working on cleaning up the code so we can make the project public on Github.
            </p>
          </div>
        </section>

        <LandingFunctions/>

        <section className="bg-white pt-16" id="product">
          <div className="container max-w-6xl mx-auto m-8">
            <h3 className={` text-4xl font-bold leading-tight text-center text-l5-secondary mx-auto`}>
              LinkFive is based in Germany
            </h3>
            <h4 className={` pt-2 text-xl leading-tight text-center text-l5-secondary mx-auto`}>
              But our users are all over the world
            </h4>
            <div className="flex flex-wrap mt-4">
              <div className="w-full">
                <img alt="LinkFive users world wide"
                     title="LinkFive users world wide"
                     style={{maxHeight: "500px"}}
                     className="mx-auto"
                     src={WorldMap}/>
              </div>
            </div>
          </div>
        </section>


        <section className="bg-white pt-16" id="product">
          <div className="container max-w-6xl mx-auto m-8">
            <h3 className={` text-4xl font-bold leading-tight text-center text-l5-secondary mx-auto`}>
              And here are some of our customers
            </h3>
            <div className="flex flex-wrap mt-16 place-content-evenly">

              <div className="w-full md:w-2/5 bg-gray-100 p-8 rounded">
                <img alt="LinkFive users world wide"
                     title="LinkFive users world wide"
                     className="mx-auto"
                     src={TrustpilotReview1}/>
              </div>
              <div className="w-full md:w-2/5 bg-gray-100 p-8 rounded">
                <img alt="LinkFive users world wide"
                     title="LinkFive users world wide"
                     className="mx-auto"
                     src={TrustpilotReview2}/>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    </>
  )
}
